const sendMail = (params, successCb, errorCb) => {
  const reqHeaders = new Headers();

  const options = {
    method: 'POST',
    headers: reqHeaders,
    body: JSON.stringify(params),
    mode: 'cors',
    cache: 'default',
  };

  fetch(window.config.network.api_url, options)
    .then((res) => res.json())
    .then((resJSON) => {
      successCb(resJSON);
    })
    .catch((error) => {
      errorCb(error);
    });
};

export default sendMail;
