const required = (value, field) => {
  if (!value) {
    switch (field) {
      case 'name':
        return 'Представьтесь, пожалуйста';
      case 'email':
        return 'Заполните поле Email';
      default:
        return 'Заполните поле';
    }
  } else return null;
};

const emailIsCorrect = (value) => {
  return /^[^@\s]+@{1}[^@\s]+$/g.test(value) ? null : 'Некорректный e-mail';
};

const rules = {
  name: [required],
  email: [emailIsCorrect, required],
};

const validator = (values) => {
  let errors = {};

  for (let key in values) {
    if (rules[key]) {
      rules[key].forEach((item) => {
        let error = item(values[key], key);
        if (error) {
          errors[key] = error;
        }
      });
    }
  }

  if (Object.keys(errors).length) {
    return errors;
  } else {
    return null;
  }
};

export default validator;
