import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  background-color: #1b2430;
  @media (max-width: 991px) {
    height: auto;
    width: auto;
    margin: 0 -15px;
    padding: 7px 15px;
  }    

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    background-color: #1b2430;
    width: 100%;
    height: inherit;
    left: 0;
  }
  a{
    color: #fff;
  }
`
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  color: #fff;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }    
  div {
    display: flex;
    align-items: center;
    img { 
      margin-right: 10px;
    }
  }
`
const Сopyrigh = styled.small`
  font-size: 14px;
  line-height: 2.93;
  letter-spacing: 0.5px;
`
const Phone = styled.div`
  font-size: 24px;
  line-height: 1.71;
  letter-spacing: 0.8px;
`
const Mail = styled.div`
  font-size: 16px;
  line-height: 2.56;
  letter-spacing: 0.5px;
`

const Footer = () => {

  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      <Container>
        {/* eslint-disable-next-line */}
        <Сopyrigh>
          &copy; 2008 — {year} Курсус
        </Сopyrigh>
        <Phone>
          <img src='/static/images/phone.png' alt='телефон'/> 
          <a href="tel:+78123093645">7-812-309-36-45</a>
        </Phone>
        <Mail>
          <img src='/static/images/mail.png' alt='email'/>
          <a href="mailto:office@cursusts.com">office@cursusts.com</a>
        </Mail>
      </Container>
    </StyledFooter>
  )
}

export default Footer