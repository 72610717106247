import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
//import window from 'window'
const timeout = 100;

const Nav = styled.nav`
  @media (max-width: 991px) {
    display: none;
    &.active {
      display: block;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    color: #000;
    opacity: 0;
    transition: 0.3s all ease-out;
    padding: 80px 15px 15px;

    & > * {
      display: block;
      margin: 0 0 15px;
      width: 100%;
      font-size: 2rem;
    }

    &.active {
      opacity: 1;
      display: block;
    }
  }
`;

const NavToggler = styled.a`
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  right: 15px;
  z-index: 2;
  background: #fff url('/static/images/menu.svg') no-repeat 50%;
  background-size: 50% auto;
  border: 1px solid #aaa;

  @media (max-width: 991px) {
    display: block;
  }
  &.active {
    background-color: #f0f0f0;
  }
`;

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 82px;
  z-index: 10;
  transition: all 0.2s ease;
  @keyframes reSize {
    from {
      height: 82px;
    }
    to {
      height: 58px;
    }
  }
  &.scrollable {
    background-color: #fff;
    border-bottom: solid 1px #e8e8e8;
    height: 58px;
  }
`;
const LogoLink = styled.a`
  cursor: pointer;
  max-width: 112px;
  img {
    max-width: 100%;
    height: auto;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1100px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1129px) {
    width: 100%;
    padding: 0 15px;
  }
`;
const MenuLink = styled(Link)`
  font-family: montserrat-semibold;
  margin-right: 60px;
  text-decoration: none;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s all ease-out;
  /* temporary */
  &:last-child {
    margin-right: 0;
  }
  &.active,
  &:hover {
    color: #fc5b0e;
  }
`;

export default class Header extends Component {
  constructor(props) {
    super(props);
    //console.log(window.history.state)
    this.state = { scrollable: false, current: 0, top: 0, menuShown: false };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    //console.log('state', this.state)

    let current = 0;
    let top = 0;
    let currentel = '';
    if (window.history.state) {
      current = window.history.state.current;
      top = window.history.state.top;
      currentel = window.history.state.currentel;
      this.setState({ current: current, top: top });
      if (top === -1) {
        console.log('currentel', currentel);
        const el = window.document.getElementById(currentel);
        if (el) {
          const header = window.document.getElementById('header');
          top = el.offsetTop - header.clientHeight;
        } else {
          top = 0;
        }
      }
    }

    if (current) {
      setTimeout(() => {
        window.scrollTo({
          top: top,
          behavior: 'smooth',
        });
      }, timeout);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    window.pageYOffset !== 0 ? this.setState({ scrollable: true }) : this.setState({ scrollable: false });
  };

  scrollTo = (id, current) => {
    if (window.location.href !== `${window.location.origin}/`) {
      navigate('/', { state: { current: current, currentel: id, top: -1 } });
      return;
    }
    const el = window.document.getElementById(id);
    const header = window.document.getElementById('header');
    let top = 0;
    if (el) {
      top = el.offsetTop - header.clientHeight;
    }
    const self = this;
    //console.log(current)
    setTimeout(() => {
      self.setState({ current: current, menuShown: false });
      window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    }, timeout);
  };

  toggleMenu = () => {
    const shown = !this.state.menuShown;
    this.setState(() => {
      return { menuShown: shown };
    });
  };

  render() {
    //const k = 230;
    // const cl1 = this.state.current === 1 ? 'active' : '';
    // const cl2 = this.state.current === 2 ? 'active' : '';
    // const cl3 = this.state.current === 3 ? 'active' : '';
    const clmenu = this.state.menuShown ? 'active' : '';
    //console.log(this.props);
    //1414-k
    //2321-k
    //3020-k
    return (
      <StyledHeader id="header" className={this.state.scrollable ? 'scrollable' : null}>
        <Container>
          <LogoLink onClick={() => this.scrollTo('#top', 0)}>
            <img src="/static/images/logo.svg" alt="Вернуться на главную страницу" />
          </LogoLink>
          <NavToggler className={clmenu} onClick={this.toggleMenu} />
          <Nav className={clmenu}>
            <MenuLink activeClassName="active" to="/products">
              Продукты
            </MenuLink>

            {/* <MenyButton className={cl1} onClick={() => this.scrollTo('solutions', 1)}>Решения</MenyButton> */}
            <MenuLink activeClassName="active" to="/solutions">
              Решения
            </MenuLink>
            {/*<MenyButton className={cl2} onClick={() => this.scrollTo('services', 2)}>Услуги</MenyButton>*/}
            <MenuLink activeClassName="active" to="/services">
              Услуги
            </MenuLink>

            {/*<MenyButton className={cl3} onClick={() => this.scrollTo('bkad', 3)}>БКАД</MenyButton>*/}
            <MenuLink activeClassName="active" to="/bkad">
              БКАД
            </MenuLink>

            {/* <MenuLink activeClassName='active' to='/services'>Услуги</MenuLink> */}
            <MenuLink activeClassName="active" to="/projects">
              Проекты
            </MenuLink>
            {/* <MenyButton onClick={() => this.scrollTo(3180)}>Проекты</MenyButton> */}
            <MenuLink activeClassName="active" to="/career">
              Карьера
            </MenuLink>
            <MenuLink activeClassName="active" to="/contacts">
              Контакты
            </MenuLink>
            {/* <MenyButton onClick={() => this.scrollTo(5480)}>Контакты</MenyButton> */}
          </Nav>
          {/* temporary commented */}
          {/* this block need put in separate component */}
          {/* <LangContainer>
            <Lang>
              <Radio type='radio' name='lang' value='ru' defaultChecked='checked' />
              <label htmlFor='ru'>RUS</label>
            </Lang>
            <Lang>
              <Radio type='radio' name='lang' value='eng' />
              <label htmlFor='eng'>ENG</label>
            </Lang>
          </LangContainer> */}
        </Container>
      </StyledHeader>
    );
  }
}
