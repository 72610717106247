import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'
import { Normalize } from 'styled-normalize'
import styled, { createGlobalStyle } from 'styled-components'

import Header from '../header'
import Footer from '../footer'

import validate from '../../modules/validator'
import sendMail from '../../modules/networkManager/sendMail'

// eslint-disable-next-line
//import slick from '../../styles/slick.css'
// eslint-disable-next-line
//import slickTheme from '../../styles/slick-theme.css'

if (typeof window !== 'undefined') {
  window.validate = validate
  window.sendMail = sendMail
}

const title = 'Курсус: программное обеспечение и решения для ИТС';//data.site.siteMetadata.title
const desc = 'Решения Курсус делают дороги безопасными и технологичными';
const keywords = 'Курсус, решения, дороги, безопасные, технологические, итс';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'montserrat-regular';
    font-weight: 400;
    src: local('montserrat-regular'), url(/static/fonts/Montserrat-Regular.eot) format('eot');
    src: local('montserrat-regular'), url(/static/fonts/Montserrat-Regular.woff) format('woff');
    src: local('montserrat-regular'), url(/static/fonts/Montserrat-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'montserrat-bold';
    font-weight: 700;
    src: local('montserrat-bold'), url(/static/fonts/Montserrat-Bold.eot) format('eot');
    src: local('montserrat-bold'), url(/static/fonts/Montserrat-Bold.woff) format('woff');
    src: local('montserrat-bold'), url(/static/fonts/Montserrat-Bold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'montserrat-semibold';
    font-weight: 600;
    src: local('montserrat-semibold'), url(/static/fonts/Montserrat-SemiBold.eot) format('eot');
    src: local('montserrat-semibold'), url(/static/fonts/Montserrat-SemiBold.woff) format('woff');
    src: local('montserrat-semibold'), url(/static/fonts/Montserrat-SemiBold.woff2) format('woff2');
  }

  html, body {
    height: 100%;
  }
  body {
    font-family: montserrat-regular;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  h1 {
    font-family: montserrat-bold;
    margin: 0;
    font-size: 30px;
    line-height: 1.27;
    color: #2d2d2d;
    @media(max-width: 991px) {
      font-size: 24px;
      margin-bottom: 1rem;

    }
  }
  .img-responsive{
    max-width: 100%;
    height: auto;
  }
  h2 {
    font-family: montserrat-semibold;
  }
  li {
    list-style-type: none;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
    text-decoration: none;
  }
  a {
    outline: none;
  }
  #___gatsby {
    height: 100%;
  }
`

const StyledLayout = styled.div`
  max-width: 1130px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1129px) {
    
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={title}
          link={[
            { rel: 'stylesheet', type:"text/css", href: '/static/styles/slick.css' },
            { rel: 'stylesheet', type:"text/css", href: '/static/styles/slick-theme.css' },
            { rel: 'icon', type: 'image/x-icon', href: '/static/images/favicon.ico' }
          ]}
          meta={[
            { charSet: 'utf-8' }, 
            { name: 'description', content: desc },
            { name: 'keywords', content: keywords },
            { name: 'yandex-verification', content: '43cc7ea3e2bdca2b' }                      
          ]}
        >
          <html lang="ru" />
          <meta property="og:url" content="https://cursusts.com" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://cursusts.com/static/images/kursus.jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
          <meta property="og:image:alt" content={title} />          
          <script src="/config.js" type="text/javascript" />
          <script>{`
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function()
            
            {(m[i].a=m[i].a||[]).push(arguments)}
            ;
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            
            ym(61635232, "init",
            
            { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true }
            );`}</script>
            <noscript>{`<div><img src="https://mc.yandex.ru/watch/61635232" style="position:absolute; left:-9999px;" alt="" /></div>`}</noscript>
        </Helmet>
        <StyledLayout id="top">
          <Header />
          {children}
          <Footer />
          <Normalize />
          <GlobalStyle />
        </StyledLayout>
      </> 
    )}
  />
)

export default Layout
